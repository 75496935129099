<template>
  <div class="terms-and-conditions mt-24" style="font-family: Montserrat">
    <div
      class="w-full flex relative"
      style="height: 340px; background-color: #eae3d9"
    >
      <div
        class="font-black leading-none max-lg:text-4xl lg:text-5xl text-center m-auto"
        style="font-family: Lora"
      >
        Privacy Policy
      </div>
    </div>
    <div class="w-full max-w-rifyo mx-auto flex flex-col relative min-h-screen">
      <div class="relative max-xl:px-7">
        <div class="w-full lg:w-7/12 mx-auto py-8">
          <div class="w-full overflow-hidden prose">
            <p>
              We respect the privacy of our customers and value your trust in
              our company. This privacy policy is intended to inform you about
              the use of personal information collected during your visit to our
              website.
            </p>
            <p>
              Your privacy is our utmost priority, we will not use your name or
              other personal information in accordance with this privacy policy.
              We will only collect information that we deem is important and
              necessary to establish a relationship with you.
            </p>
            <p>
              As Rifyo continues to expand, the Privacy Policy and Terms of Use
              of our website may change over time. This Privacy Requirement
              applies to all of your information and your account in our
              website. Please read this Privacy Policy carefully.
            </p>
            <h5><strong>Collection of Personal Information</strong></h5>
            <p>
              Rifyo will not sell or disclose our customer’s personal
              information to anyone. The information that we collect online will
              only be used internally within our company to serve you better.
            </p>
            <p>
              When you create an account on our website, here are the personal
              information that we might collect:
            </p>
            <ul>
              <li>Name</li>
              <li>Shipping address</li>
              <li>Email address</li>
              <li>Telephone / Mobile Number</li>
            </ul>
            <p>
              We will only collect this information if you are willing to submit
              said information to us. If you decide not to provide us with your
              personal information, we cannot serve you or make any agreements
              with you.
            </p>
            <h5><strong>Updating Personal Information</strong></h5>
            <p>
              You can update and change your personal information at any time in
              the Rifyo website by logging in to your account and changing your
              personal information under the ‘my account’ page.
            </p>
            <h5><strong>Account Confidentiality</strong></h5>
            <p>
              We ask that you maintain the confidentiality of the username and
              password of your account on Rifyo from a third party. We are not
              responsible for any misuses of your account in our website in the
              event of misuse by third parties.
            </p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
  import Footer from '../components/footer';

  export default {
    components: {
      Footer,
    },

    computed: {
      url() {
        return window.location.href ? window.location.href : null;
      },
    },

    methods: {
      copy() {
        navigator.clipboard.writeText(window.location.href);
        alert('Link copied.');
      },
      async share() {
        try {
          await navigator.share({
            title: 'Privacy Policy',
            url: window.location.href,
          });
        } catch (err) {
          alert(
            "Your browser doesn't support this share function. Try again on another browser."
          );
        }
      },
    },
  };
</script>

<style scoped>
  .prose {
    color: #1f252c !important;
  }
</style>
